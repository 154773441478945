const getInitials = (firstName, lastName) => {
    if(firstName && lastName){
        const firstInitial = firstName.charAt(0);
        const lastInitial = lastName.charAt(0);
        
        return firstInitial.toUpperCase() + lastInitial.toUpperCase();
    }
    return null
}

module.exports = {
    getInitials
}