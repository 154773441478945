import { FaHistory } from "react-icons/fa";
import {
  MdAddPhotoAlternate,
  MdCameraEnhance,
  MdOutlinePhotoSizeSelectLarge,
  MdFindReplace,
} from "react-icons/md";

import { Link, useOutletContext } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function AllSection() {
  const [t, i18n] = useTranslation();
  const pages = [
    { name: "PhotoGeneration", to: "/photo-generation", current: false },
  ];
  const actions = [
    {
      name: `${t("photogeneration.create")}`,
      to: "create",
      icon: MdAddPhotoAlternate,
      iconForeground: "text-teal-700",
      iconBackground: "bg-teal-50",
    },
    {
      name: `${t("photogeneration.enhance")}`,
      to: "enhance",
      icon: MdCameraEnhance,
      iconForeground: "text-purple-700",
      iconBackground: "bg-purple-50",
    },
    {
      name: `${t("photogeneration.background_remover")}`,
      to: "background-remover",
      icon: MdOutlinePhotoSizeSelectLarge,
      iconForeground: "text-yellow-700",
      iconBackground: "bg-yellow-50",
    },
    {
      name: `${t("photogeneration.image_variations")}`,
      to: "image-variations",
      icon: MdFindReplace,
      iconForeground: "text-rose-700",
      iconBackground: "bg-rose-50",
    },
    {
      name: `${t("photogeneration.your_history")}`,
      to: "your-history",
      icon: FaHistory,
      iconForeground: "text-indigo-700",
      iconBackground: "bg-indigo-50",
    },
  ];
  const { setPages, setHeading } = useOutletContext();
  useEffect(() => {
    setHeading("PhotoGeneration");

    setPages(pages);
  }, []);

  return (
    <>
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 grid gap-4 mt-3 sm:mt-5">
        {actions.map((action, actionIdx) => (
          <div
            key={actionIdx}
            className={
              "group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 border-2 rounded-lg"
            }
          >
            <div>
              <span
                className={classNames(
                  action.iconBackground,
                  action.iconForeground,
                  "inline-flex rounded-lg p-3 ring-4 ring-white"
                )}
              >
                <action.icon className="h-6 w-6" aria-hidden="true" />
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                <Link to={action.to} className="focus:outline-none">
                  {/* Extend touch target to entire panel */}
                  <span className="absolute inset-0" aria-hidden="true" />
                  {action.name}
                </Link>
              </h3>
              <p className="mt-2 text-sm text-gray-500">
                Doloribus dolores nostrum quia qui natus officia quod et
                dolorem. Sit repellendus qui ut at blanditiis et quo et
                molestiae.
              </p>
            </div>
            <span
              className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
              aria-hidden="true"
            >
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
              </svg>
            </span>
          </div>
        ))}
      </div>
    </>
  );
}
