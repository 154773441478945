import { AiOutlineClose } from "react-icons/ai";

export default function ModelOfProfaile({
  setModleProfaile,
  setUpdateFormData,
  updateFormData,
  updateProfile,
}) {
  const handelInput = (eo) => {
    setUpdateFormData({ ...updateFormData, [eo.target.name]: eo.target.value });
  };

  const handelSubmit = () => {
    updateProfile();
  };

  return (
    <>
      <div dir="rtl">
        <AiOutlineClose
          onClick={() => {
            setModleProfaile(false);
          }}
          className="h-6 w-6 cursor-pointer text-red-600"
          aria-hidden="true"
        />
      </div>
      <div className="mt-3 text-center sm:mt-5">
        <div>
          <div className="relative">
            <label
              htmlFor="first_name"
              className="absolute -top-3 left-2 inline-block bg-white px-2 text-md font-medium text-gray-900"
            >
              First Name
            </label>
            <input
              type="text"
              name="first_name"
              id="first_name"
              value={updateFormData.first_name}
              onChange={handelInput}
              className="p-2.5 block w-full rounded-md border-0  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
            />
          </div>

          <div className="relative mt-5">
            <label
              htmlFor="last_name"
              className="absolute -top-3 left-2 inline-block bg-white px-2 text-md font-medium text-gray-900"
            >
              Last Name
            </label>
            <input
              type="text"
              name="last_name"
              id="last_name"
              value={updateFormData.last_name}
              onChange={handelInput}
              className="p-2.5 block w-full rounded-md border-0  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
            />
          </div>
          <div className="relative mt-5">
            <label
              htmlFor="phone_number"
              className="absolute -top-3 left-2 inline-block bg-white px-2 text-md font-medium text-gray-900"
            >
              Phone Number
            </label>
            <input
              type="text"
              name="phone_number"
              id="phone_number"
              value={updateFormData.phone_number}
              onChange={handelInput}
              className="p-2.5 block w-full rounded-md border-0  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
            />
          </div>
          <div className="relative mt-5">
            <label
              htmlFor="Country"
              className="absolute -top-3 left-2 inline-block bg-white px-2 text-md font-medium text-gray-900"
            >
              Country
            </label>
            <input
              type="text"
              name="Country"
              id="Country"
              value={
                updateFormData.country ? updateFormData.country.name_en : ""
              }
              className="p-2.5 block w-full rounded-md border-0  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
            />
          </div>

          <div className="relative mt-5">
            <label
              htmlFor="City"
              className="absolute -top-3 left-2 inline-block bg-white px-2 text-md font-medium text-gray-900"
            >
              City
            </label>
            <input
              type="text"
              name="City"
              id="City"
              value={updateFormData.city ? updateFormData.city.name_en : ""}
              className="p-2.5 block w-full rounded-md border-0  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
            />
          </div>
        </div>
      </div>

      <div className="mt-5 sm:mt-6">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
          onClick={() => {
            handelSubmit();
            setModleProfaile(false);
          }}
        >
          Save
        </button>
      </div>
    </>
  );
}
