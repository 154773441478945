import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { AiFillDelete, AiOutlineCloudUpload } from "react-icons/ai";
import UseApiPhoto from "../UseApiPhoto";
import { ClipLoader } from 'react-spinners';

const pages = [
  { name: "PhotoGeneration", to: "/photo-generation", current: false },
  {
    name: "Create Photo",
    to: "/photo-generation/create",
    current: true,
  },
];

export default function CreatePhoto() {
  let { handleCreateImageFromPromptSubmit, loading } = UseApiPhoto();
  let [images, setImages] = useState([]);

  const { setPages, setHeading } = useOutletContext();
  useEffect(() => {
    setHeading("Create Photo");
    setPages(pages);
  }, []);

  const [prompt, setPrompt] = useState(null);
  return (
    <>
      <div className="flex items-center justify-center pt-10">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          Create image from prompt
        </h2>
      </div>
      <main className="flex justify-center items-center flex-col">
        <>
          <div className="flex justify-between items-center sm:w-3/4 w-64 mt-5">
            <div className=" w-[85%]">
              <input
                type="text"
                name="text"
                id="text"
                value={prompt}
                onChange={(e) => {
                  setPrompt(e.target.value);
                }}
                className="w-full block rounded-md border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Describe Your image"
                required
              />
            </div>
            <div>
              <button
                type="button"
                onClick={async () => {
                  await handleCreateImageFromPromptSubmit(prompt, setImages)
                }}
                className="rounded-md bg-indigo-600  px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                create
              </button>
            </div>
          </div>
          {loading ? (
            <div className="mt-28">
              <div className="loader">
                <div><ClipLoader size={60} color={'#123abc'} /></div>
                Please wait our AI bot create your images
              </div>
            </div>
          ) : ''}
          <ul className=" mt-5 grid w-fit grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">


            {images.map((image, index) => (
              <li key={index} className="flex flex-col items-center">
                <img
                  className="rounded-2xl object-cover w-full mt-20"
                  src={image}
                  alt=""
                />
                <a href={image} target="_blank">

                  <button
                    type="button"
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-32 mt-5"
                  >
                    Download
                  </button>
                </a>

              </li>
            ))}
          </ul>

        </>
      </main>
    </>
  )
}
