import { useState, useEffect } from "react";
import { Switch } from "@headlessui/react";
import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Notifications() {
  const [t] = useTranslation();
  const pages = [
    { name: `${t("sidebar.settings")}`, to: "/Settings", current: false },
    {
      name: `${t("setting.notification")}`,
      to: "/Settings/notifications",
      current: true,
    },
  ];
  const [enabled, setEnabled] = useState(false);
  const { setPages, setHeading } = useOutletContext();
  useEffect(() => {
    setHeading(`${t("setting.notification")}`);

    setPages(pages);
  }, [t]);

  useState(true);
  return (
    <>
      <div className="mt-8">
        <h2 className="text-xl font-semibold leading-7 text-gray-900">
          {`${t("setting.notification")}`}
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500 border-b border-gray-200 pb-3">
          {`${t("setting_general.setting_notification.choose")}`}
        </p>

        <Switch.Group as="div" className="flex pt-6">
          <Switch.Label
            as="dt"
            className="w-64 flex-none pr-6 font-medium text-gray-900"
            passive
          >
            {`${t("setting_general.setting_notification.email")}`}
          </Switch.Label>
          <dd className="flex flex-auto items-center justify-end">
            <Switch
              checked={enabled}
              onChange={() => {
                setEnabled(!enabled);
              }}
              className={classNames(
                enabled ? "bg-indigo-600" : "bg-gray-200",
                "flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  enabled ? "translate-x-3.5" : "translate-x-0",
                  "h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          </dd>
        </Switch.Group>

        <Switch.Group as="div" className="flex pt-6">
          <Switch.Label
            as="dt"
            className="w-64 flex-none pr-6 font-medium text-gray-900"
            passive
          >
            {`${t("setting_general.setting_notification.sms")}`}
          </Switch.Label>
          <dd className="flex flex-auto items-center justify-end">
            <Switch
              checked={enabled}
              onChange={setEnabled}
              className={classNames(
                enabled ? "bg-indigo-600" : "bg-gray-200",
                "flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  enabled ? "translate-x-3.5" : "translate-x-0",
                  "h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          </dd>
        </Switch.Group>

        <Switch.Group as="div" className="flex pt-6">
          <Switch.Label
            as="dt"
            className="w-64 flex-none pr-6 font-medium text-gray-900"
            passive
          >
            {`${t("setting_general.setting_notification.app_notification")}`}
          </Switch.Label>
          <dd className="flex flex-auto items-center justify-end">
            <Switch
              checked={enabled}
              onChange={setEnabled}
              className={classNames(
                enabled ? "bg-indigo-600" : "bg-gray-200",
                "flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  enabled ? "translate-x-3.5" : "translate-x-0",
                  "h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          </dd>
        </Switch.Group>
      </div>

      {/* mew */}
      <div className="mt-8 pt-2">
        <h2 className="text-xl font-semibold leading-7 text-gray-900">
          Notifications
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500 border-b border-gray-200 pb-3">
          Choose what you want it to work
        </p>

        <Switch.Group as="div" className="flex pt-6">
          <Switch.Label
            as="dt"
            className="w-64 flex-none pr-6 font-medium text-gray-900"
            passive
          >
            Email
          </Switch.Label>
          <dd className="flex flex-auto items-center justify-end">
            <Switch
              checked={enabled}
              onChange={setEnabled}
              className={classNames(
                enabled ? "bg-indigo-600" : "bg-gray-200",
                "flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  enabled ? "translate-x-3.5" : "translate-x-0",
                  "h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          </dd>
        </Switch.Group>

        <Switch.Group as="div" className="flex pt-6">
          <Switch.Label
            as="dt"
            className="w-64 flex-none pr-6 font-medium text-gray-900"
            passive
          >
            SMS
          </Switch.Label>
          <dd className="flex flex-auto items-center justify-end">
            <Switch
              checked={enabled}
              onChange={setEnabled}
              className={classNames(
                enabled ? "bg-indigo-600" : "bg-gray-200",
                "flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  enabled ? "translate-x-3.5" : "translate-x-0",
                  "h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          </dd>
        </Switch.Group>

        <Switch.Group as="div" className="flex pt-6">
          <Switch.Label
            as="dt"
            className="w-64 flex-none pr-6 font-medium text-gray-900"
            passive
          >
            App Notifications
          </Switch.Label>
          <dd className="flex flex-auto items-center justify-end">
            <Switch
              checked={enabled}
              onChange={setEnabled}
              className={classNames(
                enabled ? "bg-indigo-600" : "bg-gray-200",
                "flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  enabled ? "translate-x-3.5" : "translate-x-0",
                  "h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          </dd>
        </Switch.Group>
      </div>
    </>
  );
}
