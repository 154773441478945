import axios from "../../Utilities/axios";
import { useState } from "react";

export default function UseApiSecurityPage() {
  const [listSession, setlistSession] = useState([]);
  const listSessions = () => {
    axios
      .get("/settings/general/list_sessions", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res.data.sessions);
        setlistSession(res.data.sessions);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const endSpecificSession = (id) => {
    axios
      .post(
        `/settings/general/end_session/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        listSessions();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return {
    listSessions,
    listSession,
    setlistSession,
    endSpecificSession,
  };
}
