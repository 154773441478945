/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useState } from 'react'
import { Combobox } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import {
  ExclamationCircleIcon,
  PencilSquareIcon
} from '@heroicons/react/24/outline'
import BreadCrumbs from "../../Components/BreadCrumbs";
import { Link } from "react-router-dom";

const items = [
  {
    id: 1,
    name: 'Text',
    description: 'Add freeform text with basic formatting options.',
    url: '#',
    color: 'bg-indigo-500',
    icon: PencilSquareIcon,
  },
  // More items...
]
const pages = [
  { name: "Choose Your Programming Language or Framework", href: "#", current: true }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const projects = [
  { name: 'Graph API', initials: 'GA', href: '#', members: 16, bgColor: 'bg-pink-600' },
  { name: 'Component Design', initials: 'CD', href: '#', members: 12, bgColor: 'bg-purple-600' },
  { name: 'Templates', initials: 'T', href: '#', members: 16, bgColor: 'bg-yellow-500' },
  { name: 'React Components', initials: 'RC', href: '#', members: 8, bgColor: 'bg-green-500' },
]

const programmingLanguages = [
  {
    name: "JavaScript",
    code: "js",
    image: "https://cdn.iconscout.com/icon/free/png-256/javascript-2752148-2284965.png",
  },
  {
    name: "Python",
    code: "py",
    image: "https://cdn.iconscout.com/icon/free/png-256/python-3521655-2945099.png",
  },
  {
    name: "Java",
    code: "java",
    image: "https://cdn.iconscout.com/icon/free/png-256/java-43-569305.png",
  },
  {
    name: "C++",
    code: "cpp",
    image: "https://cdn.iconscout.com/icon/free/png-256/cpp-13-1175019.png",
  },
  {
    name: "Ruby",
    code: "rb",
    image: "https://cdn.iconscout.com/icon/free/png-256/ruby-3521659-2945103.png",
  },
  {
    name: "PHP",
    code: "php",
    image: "https://cdn.iconscout.com/icon/free/png-256/php-3629566-3032344.png",
  },
  {
    name: "C#",
    code: "cs",
    image: "https://cdn.iconscout.com/icon/free/png-256/csharp-3521653-2945097.png",
  },
  {
    name: "Swift",
    code: "swift",
    image: "https://cdn.iconscout.com/icon/free/png-256/swift-14-1182249.png",
  },
  {
    name: "Go",
    code: "go",
    image: "https://cdn.iconscout.com/icon/free/png-256/go-2752177-2285003.png",
  },
  {
    name: "TypeScript",
    code: "ts",
    image: "https://cdn.iconscout.com/icon/free/png-256/typescript-1174965.png",
  },
  {
    name: "Kotlin",
    code: "kt",
    image: "https://cdn.iconscout.com/icon/free/png-256/kotlin-2038873-1720086.png",
  },
  {
    name: "Rust",
    code: "rs",
    image: "https://cdn.iconscout.com/icon/free/png-256/rust-3521657-2945101.png",
  },
  {
    name: "Lua",
    code: "lua",
    image: "https://cdn.iconscout.com/icon/free/png-256/lua-3521658-2945102.png",
  },
  {
    name: "Scala",
    code: "scala",
    image: "https://cdn.iconscout.com/icon/free/png-256/scala-3521654-2945098.png",
  },
  {
    name: "Dart",
    code: "dart",
    image: "https://cdn.iconscout.com/icon/free/png-256/dart-3521660-2945104.png",
  },
  {
    name: "Perl",
    code: "pl",
    image: "https://cdn.iconscout.com/icon/free/png-256/perl-3521656-2945100.png",
  },
  {
    name: "Haskell",
    code: "hs",
    image: "https://cdn.iconscout.com/icon/free/png-256/haskell-2038875-1720088.png",
  },
  {
    name: "D",
    code: "d",
    image: "https://cdn.iconscout.com/icon/free/png-256/d-3521664-2945108.png",
  },
  {
    name: "Elixir",
    code: "ex",
    image: "https://cdn.iconscout.com/icon/free/png-256/elixir-3521662-2945106.png",
  },
  {
    name: "R",
    code: "r",
    image: "https://cdn.iconscout.com/icon/free/png-256/r-3521663-2945107.png",
  },
  {
    name: "Julia",
    code: "jl",
    image: "https://cdn.iconscout.com/icon/free/png-256/julia-3521665-2945109.png",
  },
  {
    name: "Visual Basic",
    code: "vb",
    image: "https://cdn.iconscout.com/icon/free/png-256/visual-basic-3521661-2945105.png",
  },
  {
    name: "Assembly",
    code: "asm",
    image: "https://cdn.iconscout.com/icon/free/png-256/assembly-2038872-1720085.png",
  },
  {
    name: "Objective-C",
    code: "objc",
    image: "https://cdn.iconscout.com/icon/free/png-256/objective-c-2038871-1720084.png",
  },
  {
    name: "F#",
    code: "fsharp",
    image: "https://cdn.iconscout.com/icon/free/png-256/fsharp-2038874-1720087.png",
  },
  {
    name: "COBOL",
    code: "cobol",
    image: "https://cdn.iconscout.com/icon/free/png-256/cobol-2038876-1720089.png",
  },
  {
    name: "Lisp",
    code: "lisp",
    image: "https://cdn.iconscout.com/icon/free/png-256/lisp-2038878-1720091.png",
  },
  {
    name: "Prolog",
    code: "plg",
    image: "https://cdn.iconscout.com/icon/free/png-256/prolog-2038877-1720090.png",
  },
  {
    name: "Scheme",
    code: "sch",
    image: "https://cdn.iconscout.com/icon/free/png-256/scheme-2038879-1720092.png",
  },
  {
    name: "Ada",
    code: "ada",
    image: "https://cdn.iconscout.com/icon/free/png-256/ada-2038870-1720083.png",
  },
  {
    name: "Smalltalk",
    code: "st",
    image: "https://cdn.iconscout.com/icon/free/png-256/smalltalk-2038880-1720093.png",
  },
  {
    name: "Racket",
    code: "rkt",
    image: "https://cdn.iconscout.com/icon/free/png-256/racket-2038881-1720094.png",
  },
  {
    name: "Cobalt",
    code: "cbl",
    image: "https://cdn.iconscout.com/icon/free/png-256/cobalt-2038882-1720095.png",
  },
  {
    name: "PostScript",
    code: "ps",
    image: "https://cdn.iconscout.com/icon/free/png-256/postscript-2038884-1720097.png",
  },
  {
    name: "Fortran",
    code: "f90",
    image: "https://cdn.iconscout.com/icon/free/png-256/fortran-2038885-1720098.png",
  },
  {
    name: "Turing",
    code: "tur",
    image: "https://cdn.iconscout.com/icon/free/png-256/turing-2038886-1720099.png",
  },
  {
    name: "Ocaml",
    code: "ocaml",
    image: "https://cdn.iconscout.com/icon/free/png-256/ocaml-2038887-1720100.png",
  },
  {
    name: "Bash",
    code: "sh",
    image: "https://cdn.iconscout.com/icon/free/png-256/bash-3521667-2945111.png",
  },
  {
    name: "PowerShell",
    code: "ps1",
    image: "https://cdn.iconscout.com/icon/free/png-256/powershell-3521666-2945110.png",
  },
  {
    name: "Haxe",
    code: "hx",
    image: "https://cdn.iconscout.com/icon/free/png-256/haxe-2038888-1720101.png",
  },
  {
    name: "Awk",
    code: "awk",
    image: "https://cdn.iconscout.com/icon/free/png-256/awk-2038890-1720103.png",
  },
  {
    name: "Emacs Lisp",
    code: "el",
    image: "https://cdn.iconscout.com/icon/free/png-256/emacs-2038889-1720102.png",
  },
  {
    name: "Dylan",
    code: "dylan",
    image: "https://cdn.iconscout.com/icon/free/png-256/dylan-2038891-1720104.png",
  },
  {
    name: "Logo",
    code: "logo",
    image: "https://cdn.iconscout.com/icon/free/png-256/logo-2038892-1720105.png",
  },
  {
    name: "LilyPond",
    code: "ly",
    image: "https://cdn.iconscout.com/icon/free/png-256/lilypond-2038893-1720106.png",
  },
  {
    name: "Erlang",
    code: "erl",
    image: "https://cdn.iconscout.com/icon/free/png-256/erlang-2038894-1720107.png",
  },
  {
    name: "Groovy",
    code: "groovy",
    image: "https://cdn.iconscout.com/icon/free/png-256/groovy-3521668-2945112.png",
  },
  {
    name: "Nim",
    code: "nim",
    image: "https://cdn.iconscout.com/icon/free/png-256/nim-2038895-1720108.png",
  }
]

export default function WriteCodePage() {
  const [query, setQuery] = useState('')
  const [open, setOpen] = useState(true)

  const filteredItems =
    query === ''
      ? []
      : programmingLanguages.filter((item) => {
        return item.name.toLowerCase().includes(query.toLowerCase())
      })

  return (
    <>
      <BreadCrumbs pages={pages} heading="Choose Your Programming Language or Framework" />
      <div className="p-4 sm:p-6 md:p-10 w-full relative">
        <div className=" absolute left-1/2 transform -translate-x-1/2  mx-auto max-w-xl   divide-gray-100 rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all w-full">
          <Combobox>
            <div className="relative">
              <MagnifyingGlassIcon
                className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <Combobox.Input
                onBlur={() => {
                  setQuery('')
                }}
                className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                placeholder="Search in 100+ programming lanuguages & frameworks..."
                onChange={(event) => setQuery(event.target.value)}
              />
            </div>

            {filteredItems.length > 0 && (
              <Combobox.Options static className="max-h-96 scroll-py-3 p-3 overflow-y-auto">
                {filteredItems.map((item) => (
                  <Combobox.Option
                    key={item.name}
                    value={item}
                    className={({ active }) =>
                      classNames('flex cursor-default select-none rounded-xl p-3', active && 'bg-gray-100')
                    }
                  >
                    {({ active }) => (
                      <>
                        <div
                          className='flex h-10 w-10 flex-none items-center justify-center rounded-lg'
                        >
                          <img className='h-10 w-10' src={item.image} />
                        </div>
                        <div className="ml-4 flex items-center justify-center">
                          <p
                            className={classNames(
                              'text-sm font-medium',
                              active ? 'text-gray-900' : 'text-gray-700'
                            )}
                          >
                            {item.name}
                          </p>
                        </div>
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            )}

            {query !== '' && filteredItems.length === 0 && (
              <div className="px-6 py-14 text-center text-sm sm:px-14">
                <ExclamationCircleIcon
                  type="outline"
                  name="exclamation-circle"
                  className="mx-auto h-6 w-6 text-gray-400"
                />
                <p className="mt-4 font-semibold text-gray-900">No results found</p>
                <p className="mt-2 text-gray-500">No components found for this search term. Please try again.</p>
              </div>
            )}

          </Combobox>
        </div>
      </div>

      <div className='mt-16'>
        <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
          {programmingLanguages.map((programmingLanguage) => (
            <Link to={`${programmingLanguage.name}`} key={programmingLanguage.code} className="col-span-1 flex rounded-md shadow-sm">
              <div
                className='flex w-16 flex-shrink-0 items-center justify-center  rounded-l-md border-b border-l border-t text-sm font-medium text-white'
              >
                <img className='h-12 w-12' src={programmingLanguage.image} />
              </div>
              <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                <div className="flex-1 truncate px-4 py-4 text-sm">
                  <div className="font-medium text-gray-900 hover:text-gray-600">
                    {programmingLanguage.name}
                  </div>
                  {/* <p className="text-gray-500">{project.members} Members</p> */}
                </div>
                <div className="flex-shrink-0 pr-2">
                  <button
                    type="button"
                    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="sr-only">Open options</span>
                    <svg className="h-4 w-4" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                  </button>
                </div>
              </div>
            </Link>
          ))}
        </ul>
      </div>
    </>

  )
}
