import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import moment from "moment/moment";
import { BsFillPhoneFill } from "react-icons/bs";
import { HiOutlineComputerDesktop } from "react-icons/hi2";
import { BiChevronsRight } from "react-icons/bi";

export default function ListSession({ listSession, endSpecificSession }) {
  return (
    <>
      <ul className="divide-y divide-gray-100 border mt-5 rounded-lg px-5">
        <p className=" py-4 text-lg leading-5 border-b-2 text-gray-700">
          Where you're logged in
        </p>
        {listSession.map((person, index) => (
          <li
            key={index}
            className="flex justify-between gap-x-6 py-5 max-sm:flex-col"
          >
            <div className="flex gap-x-4 justify-center items-center">
              {person.geo_info.device === "WebKit" || "Macintosh" ? (
                <HiOutlineComputerDesktop className=" h-6 w-6 text-blue-600" />
              ) : (
                <BsFillPhoneFill className=" h-6 w-6 text-blue-600" />
              )}
              <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-gray-900">
                  <span>{person.geo_info.device} .</span>
                  <span className="ml-2">
                    {person.geo_info.region} , {person.geo_info.country_name}
                  </span>
                </p>
                <p className="mt-1 flex items-center gap-2 text-sm leading-5 text-gray-500">
                  <span className="">{person.geo_info.platform}</span>
                  <BiChevronsRight className="h-4 w-4" />
                  <span>{person.geo_info.browser}.</span>
                </p>
              </div>
            </div>
            <div className="flex items-center gap-x-6  max-sm:justify-between max-sm:mt-1.5">
              <div className="sm:flex sm:flex-col sm:items-end ">
                {!person.expired & person.current ? (
                  <div className="mt-1 flex items-center gap-x-1.5">
                    <p className="text-xs leading-5 text-gray-500">
                      This Devise
                    </p>
                  </div>
                ) : !person.expired & !person.current ? (
                  <div className="mt-1 flex items-center gap-x-1.5">
                    <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                      <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                    </div>
                    <p className="text-xs leading-5 text-gray-500">Online</p>
                  </div>
                ) : person.expired ? (
                  <p className="mt-1 text-xs leading-5 text-gray-500">
                    <span className="text-sm font-medium text-blue-600 mr-1">
                      Last seen:
                    </span>
                    <time dateTime={person.lastSeenDateTime}>
                      {moment(person.last_used_at).calendar()}
                    </time>
                  </p>
                ) : (
                  console.log("object")
                )}
              </div>
              <Menu as="div" className="relative flex-none">
                <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                  <span className="sr-only">Open options</span>
                  <EllipsisVerticalIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    <Menu.Item>
                      <button
                        onClick={() => {
                          endSpecificSession(person.id);
                        }}
                        className={
                          "block px-3 py-1 text-sm leading-6 text-gray-900"
                        }
                      >
                        End session
                      </button>
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
}
