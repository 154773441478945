import { AiFillRobot } from "react-icons/ai";
import { GiRobotAntennas } from "react-icons/gi";
import { TbPhotoSearch } from "react-icons/tb";
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import BreadCrumbs from "../../Components/BreadCrumbs";
import { Link } from "react-router-dom";
import { useEffect } from "react";

window.Pusher = Pusher;

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: "PMETMGPWKKB2L09J6I8BTLJFR",
  wsHost: "abdelrahman-pusher.goddaly.com",
  wsPort: 443,
  forceTLS: false,
  encrypted: true,
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
  authEndpoint:"http://127.0.0.1:8000/broadcasting/auth"
});

const actions = [
  {
    name: "AI Chat bot",
    to: "/chatgptplus",
    icon: AiFillRobot,
    iconForeground: "text-teal-700",
    iconBackground: "bg-teal-50",
  },
  {
    name: "AI Developer bot",
    to: "/code-generator",
    icon: GiRobotAntennas,
    iconForeground: "text-purple-700",
    iconBackground: "bg-purple-50",
  },
  {
    name: "PhotoGeneration",
    to: "/photo-generation",
    icon: TbPhotoSearch,
    iconForeground: "text-sky-700",
    iconBackground: "bg-sky-50",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const pages = [{ name: "Home", href: "#", current: true }];

export default function HomePage() {

  // useEffect(() => {
  //   window.Echo.private('App.Models.User.12')
  //   .notification((notification) => {
  //     console.log(notification);
  //     // Handle the received notification data here
  //   });
  // }, [])
  return (
    <>
      <BreadCrumbs pages={pages} heading="Home" />
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 grid gap-4">
        {actions.map((action, actionIdx) => (
          <div
            key={actionIdx}
            className={
              "group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 border-2 rounded-lg"
            }
          >
            <div>
              <span
                className={classNames(
                  action.iconBackground,
                  action.iconForeground,
                  "inline-flex rounded-lg p-3 ring-4 ring-white"
                )}
              >
                <action.icon className="h-6 w-6" aria-hidden="true" />
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                <Link to={action.to} className="focus:outline-none">
                  {/* Extend touch target to entire panel */}
                  <span className="absolute inset-0" aria-hidden="true" />
                  {action.name}
                </Link>
              </h3>
              <p className="mt-2 text-sm text-gray-500">
                Doloribus dolores nostrum quia qui natus officia quod et
                dolorem. Sit repellendus qui ut at blanditiis et quo et
                molestiae.
              </p>
            </div>
            <span
              className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
              aria-hidden="true"
            >
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
              </svg>
            </span>
          </div>
        ))}
      </div>
    </>
  );
}
