import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import UseApiPhoto from "../UseApiPhoto";

const pages = [
  { name: "PhotoGeneration", to: "/photo-generation", current: false },
  { name: "Your History", to: "/photo-generation/your-history", current: true },
];

export default function YourHistory() {
  let { getPhotos, photos, setPhotos } = UseApiPhoto();
  const { setPages, setHeading } = useOutletContext();

  useEffect(() => {
    setHeading("Your History");
    setPages(pages);
    getPhotos();
  }, []);

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <ul className=" mt-5 grid w-fit grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {photos.map((photo) => (
            <li key={photo.id}>
              <img
                className="w-full rounded-2xl object-cover"
                src={photo.path}
                alt=""
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
