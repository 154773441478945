import { Link, Outlet, useLocation, } from "react-router-dom";

import BreadCrumbs from "../../Components/BreadCrumbs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PhotoGenerationPage() {

  const location = useLocation();
  const path = location.pathname;
  const value = path.substring(path.lastIndexOf("/") + 1);
  const [t, i18n] = useTranslation();

  const [pages, setPages] = useState([
    { name: "PhotoGenerationPage", to: "/photo-generation", current: true },
  ]);
  const [heading, setHeading] = useState("PhotoGeneration");

  const secondaryNavigation = [
    {
      name: `${t("photogeneration.all")}`,
      to: "",
      id: 0,
      slug: ["photo-generation"],
    },
    {
      name: `${t("photogeneration.create")}`,
      to: "create",
      id: 1,
      slug: ["create"],
    },
    {
      name: `${t("photogeneration.enhance")}`,
      to: "enhance",
      id: 2,
      slug: ["enhance"],
    },
    {
      name: `${t("photogeneration.background_remover")}`,
      to: "background-remover",
      id: 4,
      slug: ["background-remover"],
    },
    {
      name: `${t("photogeneration.image_variations")}`,
      to: "image-variations",
      id: 5,
      slug: ["image-variations"],
    },
    {
      name: `${t("photogeneration.your_history")}`,
      to: "your-history",
      id: 5,
      slug: ["your-history"],
    },
  ];

  return (
    <>
      <BreadCrumbs pages={pages} heading={heading} />
      <div className="mx-auto max-w-7xl flex flex-col">
        <aside className="flex  overflow-x-auto border-b border-gray-900/5 pb-5">
          <nav className="flex-none px-4 sm:px-6 lg:px-0">
            <ul  className="flex gap-x-3 gap-y-1 whitespace-nowrap">
              {secondaryNavigation.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.to}
                    className={classNames(
                      item.slug.includes(value)
                        ? "bg-gray-50 text-indigo-600"
                        : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                      "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold"
                    )}
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </aside>
        <Outlet context={{ setPages, setHeading }} />
      </div>
    </>
  );
}
