import { Fragment, useState, useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import i18n from '../Utilities/i18n'
import { useTheme } from '../Contexts/ThemeContext'

const languages = [
    {
        id: 1,
        name: 'English',
        code: 'en',
        avatar:
            '/images/flags/20151118161037!Flag_of_the_United_States.svg',
    },
    {
        id: 2,
        name: 'Arabic',
        code: 'ar',
        avatar:
            '/images/flags/Flag_of_Saudi_Arabia.svg.png',
    },
    {
        id: 3,
        name: 'French',
        code: 'fr',
        avatar:
            '/images/flags/Flag_of_France.png',
    }
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function LanguageMenu() {
    const [selected, setSelected] = useState(null)
    const { setDir } = useTheme();

    useEffect(() => {
        languages.forEach(language => {
            if (language.code == (localStorage.getItem('i18nextLng') ?? 'en')) {
                setSelected(language)
            }
        });
    }, [])


    const handleLanguageSet = (l) => {
        setSelected(l)
        localStorage.setItem('i18nextLng',l.code)
        i18n.changeLanguage(l.code)
        setDir(l.code == 'ar' ? 'rtl' : 'ltr')
    }
    return (
        <>
            {selected && (
                <Listbox value={selected} onChange={handleLanguageSet}>
                    {({ open }) => (
                        <>
                            <div className="relative mt-2">
                                <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                                    <span className="flex items-center">
                                        <img src={selected.avatar} alt="" className="h-6 w-6 flex-shrink-0 rounded-full" />
                                        <span className="ml-3 mr-3 block truncate">{selected.name}</span>
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </span>
                                </Listbox.Button>

                                <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        {languages.map((person) => (
                                            <Listbox.Option
                                                key={person.id}
                                                className={({ active }) =>
                                                    classNames(
                                                        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                                    )
                                                }
                                                value={person}
                                            >
                                                {({ selected, active }) => (
                                                    <>
                                                        <div className="flex items-center">
                                                            <img src={person.avatar} alt="" className="h-5 w-5 flex-shrink-0 rounded-full" />
                                                            <span
                                                                className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                                            >
                                                                {person.name}
                                                            </span>
                                                        </div>

                                                        {selected ? (
                                                            <span
                                                                className={classNames(
                                                                    active ? 'text-white' : 'text-indigo-600',
                                                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                )}
                                                            >
                                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                            </span>
                                                        ) : null}
                                                    </>
                                                )}
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                </Transition>
                            </div>
                        </>
                    )}
                </Listbox>
            )}
        </>

    )
}
