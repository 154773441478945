import React, { useState, useEffect } from "react";
import "../../index.css"
import Pusher from 'pusher-js';
import axios from '../../Utilities/axios';
import { v4 as uuidv4 } from 'uuid';

const ChatPage = () => {
  const [messages, setMessages] = useState({});
  const [inputText, setInputText] = useState("");
  const [channel, setChannel] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_ID, {
      wsHost: process.env.REACT_APP_PUSHER_HOST,
      wsPort: process.env.REACT_APP_PUSHER_PORT,
      forceTLS: false,
      encrypted: true,
      disableStats: true,
      enabledTransports: ['ws', 'wss'],
      authEndpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT,
      auth: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    });

    const channel = pusher.subscribe(`private-App.Models.User.${JSON.parse(localStorage.getItem('user'))['id']}`);
    channel.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', function (data) {
      
      setMessages((prevValue) => ({
        ...prevValue,
        [data['message_id']]: {
          text:(prevValue[data['message_id']] ? prevValue[data['message_id']]['text']: '') + data['text'],
          role:'assistant'
        }
      }))
    });
  }, [])
  useEffect(() => {
    console.log(messages)
}, [messages])



  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    
    event.preventDefault();
    setInputText("");
    if (inputText.trim() === "") return;
    setMessages({
      ...messages,
      [uuidv4()]: {
        text:inputText,
        role:'user'
      }
    })
    setLoading(true)
    await axios.post('chatgpt-plus/send-message', {
      "message": inputText
    })
    setLoading(false)

  };

  return (
    <div
      className="flex flex-col bg-gray-200/30"
      style={{
        maxHeight: "calc(100vh - 125px)",
        height: "calc(100vh - 125px)",
      }}
    >
      <div
        className="flex-grow flex flex-col px-4 py-8 overflow-y-auto scrollbar-hide"
        style={{
          maxHeight: "calc(100vh - 125px)",
          height: "calc(100vh - 125px)",
        }}
      >
        {Object.values(messages).map((message, index) => (
          <div
            key={index}
            className={`flex items-start mb-4 ${message.role == "user" ? "justify-end" : "justify-start"
              }`}
          >
            <div
              className={`max-w-xs rounded-lg p-4 ${message.role == "user"
                  ? "bg-blue-500 text-white self-end"
                  : "bg-white text-gray-800 self-start"
                }`}
            >
              {message.text}
            </div>
          </div>
        ))}
      </div>
      {!loading ? (
        <form onSubmit={handleFormSubmit} className="flex p-4 bg-gray-100 ">
          <input
            type="text"
            value={inputText}
            onChange={handleInputChange}
            className="flex-grow rounded-full py-2 px-4 outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Type a message..."
          />
          <button
            type="submit"
            className="ml-4 bg-blue-500 hover:bg-blue-600 text-white py-2 px-6 rounded-full"
          >
            Send
          </button>
        </form>
      ): 'Loading ...'}

    </div>
  );
};

export default ChatPage;
