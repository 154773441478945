import axios from "../../Utilities/axios";
import { useState } from "react";
import { useTheme } from "../../Contexts/ThemeContext";

export default function UseApiPhoto() {
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setCredits } = useTheme();

  const getPhotos = () => {
    setLoading(true);
    axios
      .get(`/images-ai/history`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res);
        setPhotos(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const handleEnhanceSubmit = async (image, setImage) => {
    setLoading(true);

    const formData = new FormData();
    formData.append('image', image);
  
    try {
      const response = await axios.post('/images-ai/enhance', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 200) {
      setLoading(false);
  
      setImage(response.data.path)
      setCredits(response.data.left_credits)

      console.log('response',response);
      }
    } catch (response) {
      setLoading(false);
      if (response.status === 402) {
        alert('Sorry, you do not have enough credits, please charge and try again.');
      }
    }
  };  

  const handleRemoveBgSubmit = async (image, setImage) => {
    setLoading(true);

    const formData = new FormData();
    formData.append('image', image);
  
    try {
      const response = await axios.post('/images-ai/remove_bg', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 200) {
      setLoading(false);
  
      setImage(response.data.path)
      console.log('response',response);
      setCredits(response.data.left_credits)
      }

    } catch (response) {
      setLoading(false);
      if (response.status === 402) {
        alert('Sorry, you do not have enough credits, please charge and try again.');
      }
    }
  };  

  const handleVariationsSubmit = async (image, setImages) => {
    setLoading(true);

    const formData = new FormData();
    formData.append('image', image);
  
    try {
      const response = await axios.post('/images-ai/variations', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 200) {
      setLoading(false);
  
      setImages(response.data.paths)
      setCredits(response.data.left_credits)

      console.log('response',response);
      }
    } catch (response) {
      setLoading(false);
      if (response.status === 402) {
        alert('Sorry, you do not have enough credits, please charge and try again.');
      }
    }
  };  

  const handleCreateImageFromPromptSubmit = async (prompt, setImages) => {
    setLoading(true);

    const formData = new FormData();
    formData.append('prompt', prompt);
  
    try {
      const response = await axios.post('/images-ai/create', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        setImages(response.data.paths)
        setCredits(response.data.left_credits)
        console.log('response',response);
        setLoading(false);
      }

    } catch (response) {
      setLoading(false);
      if (response.status === 402) {
        alert('Sorry, you do not have enough credits, please charge and try again.');
      }
    }
  };  

  return {
    getPhotos,
    handleEnhanceSubmit,
    handleRemoveBgSubmit,
    handleCreateImageFromPromptSubmit,
    handleVariationsSubmit,
    photos,
    setPhotos,
    loading
  };
}
