import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

const transactions = [
  {
    Date: "15 / 3 / 2023",
    Description: "User",
    ID: 12315467879,
    Status: "Completed",
    Currency: "USD",
    Gross: "300",
    Fee: "-12",
    Net: "-10.54",
  },{
    Date: "15 / 3 / 2023",
    Description: "User",
    ID: 12315467879,
    Status: "Completed",
    Currency: "USD",
    Gross: "300",
    Fee: "-12",
    Net: "-10.54",
  },{
    Date: "15 / 3 / 2023",
    Description: "User",
    ID: 12315467879,
    Status: "Completed",
    Currency: "USD",
    Gross: "300",
    Fee: "-12",
    Net: "-10.54",
  },{
    Date: "15 / 3 / 2023",
    Description: "User",
    ID: 12315467879,
    Status: "Completed",
    Currency: "USD",
    Gross: "300",
    Fee: "-12",
    Net: "-10.54",
  },
  {
    Date: "15 / 3 / 2023",
    Description: "User",
    ID: 12315467879,
    Status: "Completed",
    Currency: "USD",
    Gross: "300",
    Fee: "-12",
    Net: "-10.54",
  },
  {
    Date: "15 / 3 / 2023",
    Description: "User",
    ID: 12315467879,
    Status: "Completed",
    Currency: "USD",
    Gross: "300",
    Fee: "-12",
    Net: "-10.54",
  },
  {
    Date: "15 / 3 / 2023",
    Description: "User",
    ID: 12315467879,
    Status: "Completed",
    Currency: "USD",
    Gross: "300",
    Fee: "-12",
    Net: "-10.54",
  },
  {
    Date: "15 / 3 / 2023",
    Description: "User",
    ID: 12315467879,
    Status: "Completed",
    Currency: "USD",
    Gross: "300",
    Fee: "-12",
    Net: "-10.54",
  },
  // More transactions...
];


export default function Billing() {
  const [t] =useTranslation()
  const { setPages, setHeading } = useOutletContext();
  const pages = [
    { name: `${t("sidebar.settings")}`, to: "/Settings", current: false },
    { name: `${t("setting.billing")}`, to: "/Settings/billing", current: true },
  ];
  useEffect(() => {
    setHeading(`${t("setting.billing")}`);

    setPages(pages);
  }, [t]);


  return (
    <div className="px-4 sm:px-6 lg:px-8 ">
      <div className="sm:flex sm:items-center mt-4">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Tom
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A table of placeholder stock market data that does not make any
            sense.
          </p>
        </div>
      </div>
      <div className="mt-4 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Currency
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Gross
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Fee
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Net
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {transactions.map((transaction, index) => (
                  <tr key={index}  className="even:bg-gray-50">
                    <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                      {transaction.Date}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                      <p>General Payment:{transaction.Description}</p>
                      <p>ID:{transaction.ID}</p>
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                      {transaction.Status}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                      {transaction.Currency}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                      {transaction.Gross}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                      {transaction.Fee}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                      {transaction.Net}
                    </td>
                    <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0"></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
