// src/contexts/ThemeContext.js
import { createContext, useContext, useState } from "react";

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);
  const [langMode, setLangMode] = useState("en");
  const [credits, setCredits] = useState(null);
  const [dir, setDir] = useState(localStorage.getItem('i18nextLng') == 'ar' ? 'rtl' : 'ltr');

  const toggleDarkMode = () => setDarkMode(!darkMode);

  return (
    <ThemeContext.Provider
      value={{ darkMode, toggleDarkMode, langMode, setLangMode, dir, setDir, credits,  setCredits}}
    >
      {children}
    </ThemeContext.Provider>
  );
};
