// First we need to import axios.js
import axios from 'axios';
// Next we make an 'instance' of it
const instance = axios.create({
    // .. where we make our configurations
    baseURL: process.env.REACT_APP_API_URL
});

// Where you would set stuff like your 'Authorization' header, etc ...
instance.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

instance.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {
            // Handle 401 Unauthorized error here
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            // window.location.href = process.env.REACT_APP_LANDING_PAGE_URL+'/auth/login'
        }
        return Promise.reject(error.response);
    }
);

export default instance;