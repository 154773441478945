import axios from "../../Utilities/axios";
import { useState } from "react";
export default function UseApi({ setModleProfaile, setmodleOfBank }) {
  const [data, setData] = useState({});
  const [updateFormData, setUpdateFormData] = useState({});
  const [creditCard, setCreditCard] = useState([]);

  const viwoProfile = () => {
    axios
      .get(`/settings/general/view_profile`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        // console.log(res.data.user);
        setData(res.data.user);
        setUpdateFormData(res.data.user);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateProfile = () => {
    axios
      .put(
        `/settings/general/update_profile`,
        updateFormData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setData(res.data.user);
        setUpdateFormData(res.data.user);
        setModleProfaile(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };



  const viweCreditCard = () => {
    axios
      .get(`/credit-cards`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        // console.log(res.data.credit_cards);
        setCreditCard(res.data.credit_cards);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [card_holder_name, setcard_holder_name] = useState();
  const [card_number, setcard_number] = useState();
  const [expiration_date, setexpiration_date] = useState();

  const addNewCreditCard = () => {
    axios
      .post(
        `/credit-cards`,
        { card_holder_name, card_number, expiration_date },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setmodleOfBank(false);
        viweCreditCard();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteCreditCard = (id) => {
    axios
      .delete(`/credit-cards/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res);
        viweCreditCard();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return {
    viwoProfile,
    updateProfile,

    viweCreditCard,
    addNewCreditCard,
    deleteCreditCard,

    data,
    setData,

    updateFormData,
    setUpdateFormData,


    creditCard,
    setCreditCard,

    card_holder_name,
    setcard_holder_name,

    card_number,
    setcard_number,

    expiration_date,
    setexpiration_date,
  };
}
