import { Outlet } from "react-router-dom";
import useAuth from "../Utilities/useAuth";
import React, { useEffect, useState } from 'react';
import axios from '../Utilities/axios';
import { ClipLoader } from 'react-spinners';
import { Route, Redirect } from 'react-router-dom';
import { useTheme } from "../Contexts/ThemeContext";

const AuthenticatedRoute = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const { setCredits } = useTheme();

    useEffect(() => {
        const checkTokenValidity = async () => {
            try {

                let response = await axios.get('/settings/general/view_profile', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                });

                if (response.status === 200) {
                    localStorage.setItem('user',JSON.stringify(response.data.user))
                    setCredits(response.data.user.credits)
                    setIsAuthenticated(true);
                } else {
                    setIsAuthenticated(false);
                }
            } catch (error) {
                console.error('Error checking token validity:', error);
                setIsAuthenticated(false);
            } finally {
                setIsLoading(false);
            }
        };

        checkTokenValidity();
    }, []);
    if (isLoading) {
        return (
            <div className="loader-container">
                <div className="loader">
                    <ClipLoader size={60} color={'#123abc'} />
                </div>
            </div>
        )
    }
    return isAuthenticated ? <Outlet /> : window.location.href = process.env.REACT_APP_LANDING_PAGE_URL+"/auth/login";
}

export default AuthenticatedRoute;