import { useState } from 'react'
import { Combobox } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import {
    ExclamationCircleIcon,
    PencilSquareIcon,
    ChevronDoubleRightIcon
} from '@heroicons/react/24/outline'
import { useNavigate } from "react-router-dom";
import BreadCrumbs from "../../../Components/BreadCrumbs";
import { Link, useParams } from "react-router-dom";
const pages = [
    { name: "Choose code language", href: "#", current: true },
    { name: "Ask our ai developer bot", href: "#", current: true }
];

export default function CodeAskPage() {
    const navigate = useNavigate();
    let { codeLanguage } = useParams()
    const [query, setQuery] = useState('')

    return (
        <>
            <BreadCrumbs pages={pages} heading={`Ask our ai developer bot to write ${codeLanguage} code`} />
            <div className="p-4 sm:p-6 md:p-10 w-full relative">
                <div className=" absolute left-1/2 transform -translate-x-1/2  mx-auto max-w-xl   divide-gray-100 rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all w-full">
                    <Combobox>
                        <div className="relative">
                            <div className='absolute right-4 top-3.5 cursor-pointer '
                                onClick={()=>{
                                    navigate(`code-files/${query}`);
                                }}
                            >
                                <ChevronDoubleRightIcon
                                    className="h-5 w-5 text-gray-400 hover:text-indigo-700"
                                    aria-hidden="true"
                                />
                            </div>

                            <Combobox.Input
                                className="h-12 w-full border-0 bg-transparent pl-6 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                                placeholder={`Write your ${codeLanguage} code idea, for example: Get the width of face from image`}
                                onChange={(event) => {
                                    setQuery(event.target.value)
                                }}
                            />
                        </div>

                    </Combobox>
                </div>
            </div>

            <div className='mt-16'>

            </div>
        </>

    )
}
