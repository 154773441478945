import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "./Contexts/ThemeContext";
import "./Utilities/i18n";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  // </React.StrictMode>
  <ThemeProvider>
    <App />
  </ThemeProvider>
);
