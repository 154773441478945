import React from "react";
import { Routes, Route } from "react-router-dom";

// Sigin & Register Pages
import Signin from "../Pages/Log/Signin";
import Registr from "../Pages/Log/Registr";

// Layout {Sidebar & Header }
import Layout from "../Layouts/Layout1/Layout";

// Home
import HomePage from "../Pages/Home/HomePage";

// Chat
import ChatGptPlusPage from "../Pages/ChatGptPlus/ChatGptPlusPage";

// Code Generation
import CodeGeneratorPage from "../Pages/CodeGeneratorPage";
import CodeFilesPage from "../Pages/CodeGeneratorPage/CodeFilesPage";
import CodeAskPage from "../Pages/CodeGeneratorPage/CodeAsk/CodeAskPage";

// Sittings Page & Section
import SettingPage from "../Pages/Settings/SettingPage";
import AllSetting from "../Pages/Settings/Sections/AllSection";
import General from "../Pages/Settings/Sections/General";
import SecurityPage from "../Pages/Settings/Sections/SecurityPage";
import Notifications from "../Pages/Settings/Sections/Notifications";
import Plan from "../Pages/Settings/Sections/Plan";
import Billing from "../Pages/Settings/Sections/Billing";
import TeamMember from "../Pages/Settings/Sections/TeamMember";

// photo generation
import PhotoGenerationPage from "../Pages/PhotoGeneration/PhotoGenerationPage";
import AllSection from "../Pages/PhotoGeneration/Section/AllSection";
import CreatePhoto from "../Pages/PhotoGeneration/Section/CreatePhoto";
import EnhancePhoto from "../Pages/PhotoGeneration/Section/EnhancePhoto";
import BackgroundRemover from "../Pages/PhotoGeneration/Section/BackgroundRemover";
import ImageVariations from "../Pages/PhotoGeneration/Section/ImageVariations";
import YourHistory from "../Pages/PhotoGeneration/Section/YourHistory";
import AuthPramPage from '../Pages/AuthPramPage'
import ProtectedRoutes from "./ProtectedRoutes";

const Router = () => {
  return (
    <Routes>
      {/* to get token from  Log-in*/}
      <Route path="/auth/token" element={<AuthPramPage />} />




      {/* pages of code generation routes */}
      <Route element={<Layout><ProtectedRoutes /></Layout>}>
        <Route
          // exact
          path="/"
          element={
            <HomePage />
          }
        />
      {/* chat Route */}
      <Route
        path="/chatgptplus"
        element={
          <ChatGptPlusPage />
        }
      />

        {/* setting pages Routes */}
        <Route
          path="/Settings"
          element={
            <SettingPage />
          }
        >
          <Route element={<AllSetting />} index />
          <Route path="general" element={<General />}></Route>
          <Route path="plan" element={<Plan />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="teammembers" element={<TeamMember />} />
          <Route path="security" element={<SecurityPage />} />
          <Route path="billing" element={<Billing />} />
        </Route>
        <Route
          path="/code-generator"
          element={
            <CodeGeneratorPage />
          }
        />
        <Route
          path="/code-generator/:codeLanguage"
          element={
            <CodeAskPage />
          }
        />
        <Route
          path="/code-generator/:codeLanguage/code-files/:query"
          element={
            <CodeFilesPage />
          }
        />
        <Route
          path="/photo-generation"
          element={
            <PhotoGenerationPage />
          }
        >
          <Route element={<AllSection />} index />
          <Route path="create" element={<CreatePhoto />} />
          <Route path="enhance" element={<EnhancePhoto />} />
          <Route path="background-remover" element={<BackgroundRemover />} />
          <Route path="image-variations" element={<ImageVariations />} />
          <Route path="your-history" element={<YourHistory />} />
        </Route>
      </Route>


      {/* PhotoGeneration */}

    </Routes>
  );
};

export default Router;
