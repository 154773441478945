
import CodeEditor from '@uiw/react-textarea-code-editor';
import { useEffect, useState } from 'react';
import BreadCrumbs from '../../../Components/BreadCrumbs'
import Menu from '../../../Components/Menu'
import { ArrowDownTrayIcon, PlayIcon } from '@heroicons/react/20/solid'
import { useParams } from "react-router-dom";
import useApi from "./useApi";
import { ClipLoader } from 'react-spinners';

const pages = [
  { name: "Choose Your Programming Language or Framework", href: "#", current: true },
  { name: "Code files", href: "#", current: true },
];

export default function Example() {
  const [code, setCode] = useState('');
  let { codeLanguage, query } = useParams()
  let { Generate, loading, navigation } = useApi()
  useEffect(() => {
    Generate(codeLanguage, query)
  }, [])
  return (
    <>
      {loading ?
        (<div className="loader-container">
          <div className="loader">
            <div><ClipLoader size={60} color={'#123abc'} /></div>
            Please wait our AI Developer bot preparing your code files
          </div>
        </div>) :
        (<>
          <BreadCrumbs pages={pages} heading="Code Editor" />
          <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 -mt-8">
            <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-2">
                <h3 className="text-base font-semibold leading-6 text-gray-900">Explorer</h3>
              </div>
              <div className="ml-4 mt-2 flex-shrink-0">
                <button
                  type="button"
                  className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"

                >
                  <ArrowDownTrayIcon
                    className='h-6 w-6 shrink-0 pr-2'
                    aria-hidden="true"
                  />
                  Download compressed file (.zip)
                </button>
                <button
                  type="button"
                  className="relative inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ml-2"
                >
                  <PlayIcon
                    className='h-6 w-6 shrink-0 pr-2'
                    aria-hidden="true"
                  />
                  Run
                </button>
              </div>
            </div>
          </div>
          <div className='flex'>
            <div className="flex grow flex-col overflow-y-auto gap-y-5 border-r border-gray-200 bg-white px-6 w-1/4 pt-5"
              style={{
                fontSize: 12,
                fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                maxHeight: 'calc(100vh - 200px)'
              }}
            >
              <Menu
                navigation={navigation}
                onClick={(code) => {
                  setCode(code)
                }}
              />
            </div>
            <div className=' w-9/12 overflow-y-auto'
              style={{
                maxHeight: 'calc(100vh - 200px)'

              }}
            >
              <CodeEditor
                value={code}
                language={codeLanguage}
                placeholder={`Please enter ${codeLanguage} code.`}
                onChange={(evn) => setCode(evn.target.value)}
                padding={15}
                readOnly={true}
                data-color-mode='light'
                style={{
                  fontSize: 12,
                  fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                  minHeight: 'calc(100vh - 200px)'
                }}
              />
            </div>
          </div>
        </>)}

    </>

  )
}
