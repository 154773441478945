import axios from '../../../Utilities/axios'
import { useEffect, useState } from 'react'
import { useTheme } from "../../../Contexts/ThemeContext";

export default function useApi() {
    const [navigation, setNavigation] = useState([]);
    const [loading, setLoading] = useState(false);
    const { setCredits } = useTheme();

    const Generate = (language, query) => {
        setLoading(true)
        axios
            .post(`/developer-ai/generate`, {
                language:language,
                code:query
            })
            .then((res) => {
                setNavigation(res.data.data)
                setLoading(false)
                setCredits(res.data.left_credits)
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }


    return {
        Generate,
        navigation,
        loading
    }
}
