import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon, FolderIcon, CodeBracketIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const renderChild = (item, onClick) => {
    return <Disclosure as="div">
        {({ open }) => (
            <>
                <Disclosure.Button
                    className={classNames(
                        item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                        'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700'
                    )}
                >
                    <ChevronRightIcon
                        className={classNames(
                            open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                            'h-5 w-5 shrink-0'
                        )}
                        aria-hidden="true"
                    />
                    <FolderIcon
                        className='h-5 w-5 shrink-0'
                        aria-hidden="true"
                    />
                    {item.name}
                </Disclosure.Button>
                <Disclosure.Panel as="ul" className="mt-1 px-2">
                    {item.children.map((subItem) => (
                        <li key={subItem.name} >
                            {subItem.children ? (
                                renderChild(subItem, onClick)

                            ) : (
                                <div
                                as="div"
                                className={classNames(
                                    subItem.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                    ' cursor-pointer block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700 flex'
                                )}
                                onClick={() => {
                                    onClick(subItem.content)
                                }}
                            >
                                <CodeBracketIcon
                                    className='h-6 w-6 shrink-0 pr-2'
                                    aria-hidden="true"
                                />
                                {subItem.name}
                            </div>
                            )}

                        </li>
                    ))}
                </Disclosure.Panel>
            </>
        )}
    </Disclosure>
}

export default function Menu({ navigation, onClick }) {
    return <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
            <li>
                <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                        <li key={item.name} >
                            {item.children ? (
                                renderChild(item, onClick)
                            ) : (
                                <a
                                onClick={() => {
                                    onClick(item.content)
                                }}
                                className={classNames(
                                    item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                    'cursor-pointer block rounded-md py-2 pr-2 pl-10 text-sm leading-6 font-semibold text-gray-700'
                                )}
                            >
                                <CodeBracketIcon
                                    className='h-5 w-5 shrink-0'
                                    aria-hidden="true"
                                />
                                {item.name}
                            </a>
                            )}
                        </li>
                    ))}
                </ul>
            </li>
        </ul>
    </nav>
}
