import { AiOutlineClose } from "react-icons/ai";

export default function ModelOfBank({
  setMmodleOfBank,
  addNewCreditCard,
  card_holder_name,
  setcard_holder_name,
  setcard_number,
  card_number,
  expiration_date,
  setexpiration_date,
}) {
  return (
    <>
      <div>
        <div dir="rtl">
          <AiOutlineClose
            onClick={() => {
              setMmodleOfBank(false);
            }}
            className="h-6 w-6 cursor-pointer text-red-600"
            aria-hidden="true"
          />
        </div>
        <fieldset>
          <legend className="block text-sm font-medium leading-6 text-gray-900 ">
            Card Details
          </legend>
          <div className="mt-2 -space-y-px rounded-md bg-white shadow-sm">
            <div>
              <label htmlFor="card-number" className="sr-only">
                Card number
              </label>
              <input
                type="text"
                name="card-number"
                id="card-number"
                className="relative block w-full rounded-none rounded-t-md border-0 bg-transparent p-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 outline-none  placeholder:text-gray-400  sm:text-sm sm:leading-6"
                placeholder="Card number"
                value={card_number}
                onChange={(eo) => {
                  setcard_number(eo.target.value);
                }}
              />
            </div>
            <div className="flex -space-x-px">
              <div className="w-1/2 min-w-0 flex-1">
                <label htmlFor="card-name" className="sr-only">
                  Card Name
                </label>
                <input
                  type="text"
                  name="card-name"
                  id="card-name"
                  className="relative block w-full rounded-none rounded-bl-md border-0 bg-transparent p-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 outline-none  placeholder:text-gray-400  sm:text-sm sm:leading-6"
                  placeholder="Card Name"
                  value={card_holder_name}
                  onChange={(eo) => {
                    setcard_holder_name(eo.target.value);
                  }}
                />
              </div>
              <div className="w-1/2 min-w-0 flex-1">
                <label htmlFor="card-expiration-date" className="sr-only">
                  Expiration date
                </label>
                <input
                  type="text"
                  name="card-expiration-date"
                  id="card-expiration-date"
                  className="relative block w-full rounded-none rounded-bl-md border-0 bg-transparent p-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 outline-none  placeholder:text-gray-400  sm:text-sm sm:leading-6"
                  placeholder="MM / YY"
                  value={expiration_date}
                  onChange={(eo) => {
                    setexpiration_date(eo.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </fieldset>
        <div className="mt-5 sm:mt-6">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
            onClick={() => {
              addNewCreditCard();
              setMmodleOfBank(false);
            }}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
}
