import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { AiFillDelete, AiOutlineCloudUpload } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import UseApiPhoto from "../UseApiPhoto";

const pages = [
  { name: "PhotoGeneration", to: "/photo-generation", current: false },
  { name: "Enhance", to: "/photo-generation/enhance", current: true },
];

export default function EnhancePhoto() {
  const [t, i18n] = useTranslation();
  let { handleEnhanceSubmit } = UseApiPhoto();
  const { setPages, setHeading } = useOutletContext();
  useEffect(() => {
    setHeading("Enhance");
    setPages(pages);
  }, []);

  const [image, setImage] = useState(null);
  const [imageToRequest, setImageToRequest] = useState(null);
  const [filseName, setFileName] = useState("No selected file");
  const [changeFormImage, setChangeFormImage] = useState(false);
  const [changeMainDiv, setChangeMainDiv] = useState(false);
  const [changeSecondSectionDiv, setchangeSecondSectionDiv] = useState(false);

  return (
    <>
      <div className="flex items-center justify-center pt-2">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          {`${t("photogeneration.enhance")}`}
        </h2>
      </div>
      {!changeMainDiv ? (
        <main className="flex justify-center items-center flex-col">
          {!changeFormImage ? (
            <form
              onClick={() => document.querySelector(".input-filed").click()}
              className="mt-10 flex flex-col justify-center items-center border-2 border-dashed border-blue-500 h-40 w-64 sm:h-60 sm:w-3/4 cursor-pointer rounded-lg"
            >
              <input
                type="file"
                accept="image/png, image/jpeg, image/jpg, image/webp"
                className="input-filed"
                hidden
                onChange={({ target: { files } }) => {
                  files[0] && setFileName(files[0].name);

                  if (files) {
                    setImage(URL.createObjectURL(files[0]));
                    setImageToRequest(files[0])
                    console.log(URL.createObjectURL(files[0]))
                  }
                  setChangeFormImage(true);
                }}
              />

              <>
                <AiOutlineCloudUpload className=" text-3xl text-[#1475cf]" />
                <p>{`${t("photogeneration_section.browse_file")}`}</p>
              </>
            </form>
          ) : (
            <>
              <div className="mt-10 flex justify-center items-center">
                <img
                  src={image}
                  alt={filseName}
                  width={512}
                  height={512}
                  className="rounded-xl max-sm:w-[75%] max-sm:h-[75%]"
                />
              </div>
              <section
                dir="rtl"
                className="my-3 flex justify-between items-center py-4 px-5 bg-[#e9f0ff] w-64 sm:w-[500px]"
              >
                <img src={image} alt={filseName} width={50} />
                <span className="flex items-center">
                  {filseName.slice(0, 10)}.....
                </span>
                <AiFillDelete
                  className="cursor-pointer text-red-600/80"
                  onClick={() => {
                    setChangeFormImage(false);
                    setFileName("No Selected File");
                    setImage(null);
                  }}
                />
              </section>
              <button
                onClick={async () => {
                  setChangeMainDiv(true);
                  await handleEnhanceSubmit(imageToRequest, setImage)
                  setchangeSecondSectionDiv(true);
                }}
                type="button"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Enhance
              </button>
            </>
          )}
        </main>
      ) : (
        <div className=" flex flex-col justify-center items-center gap-5">
          {!changeSecondSectionDiv ? (
            <div className="mt-10 h-60 w-60 sm:h-80 sm:w-[300px] bg-gray-400 rounded-3xl"></div>
          ) : (
            <div className="mt-10 flex flex-col justify-center items-center w-[240px] sm:w-[400px] bg-gray-300/10 rounded-3xl">
              <div className="flex justify-center items-center">
                <img
                  src={image}
                  alt={filseName}
                  width={512}
                  height={512}
                  className="rounded-xl max-sm:w-[75%] max-sm:h-[75%] mt-5"
                />
              </div>

              <div className="my-5 flex justify-center items-center gap-5 sm:gap-10 w-[240px] sm:w-[400px]">
                <button
                  type="button"
                  onClick={() => {
                    setChangeMainDiv(false);
                    setChangeFormImage(false);
                    setchangeSecondSectionDiv(false);
                  }}
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  New Upload
                </button>
                <button
                  type="button"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                <a href={image} download={`GPT Araby ${filseName}`}>
                    Download
                  </a>
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
