import { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";

import { useOutletContext } from "react-router-dom";
import PopUp from "../../../Components/Popup";
import ModelOfProfaile from "../../../Components/Models/ModelOfProfaile";
import ModelOfBank from "../../../Components/Models/ModelOfBank";
import UseApi from "../UseApi";
import moment from "moment/moment";
import { RiVisaLine } from "react-icons/ri";
import { BsFillTrashFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function General() {
  const [t] = useTranslation();
  const { setPages, setHeading } = useOutletContext();

  const pages = [
    { name: `${t("sidebar.settings")}`, to: "/Settings", current: false },
    { name: `${t("setting.general")}`, to: "/Settings/general", current: true },
  ];
  useEffect(() => {
    setHeading(`${t("setting.general")}`);
    setPages(pages);
  }, [t]);
  // open popup from profile
  const [modleProfaile, setModleProfaile] = useState(false);
  const [modleOfBank, setmodleOfBank] = useState(false);

  let {
    viwoProfile,
    updateProfile,
    viweCreditCard,
    deleteCreditCard,

    updateFormData,
    data,

    creditCard,
    setCreditCard,

    setUpdateFormData,

    addNewCreditCard,
    card_holder_name,

    setcard_holder_name,
    card_number,
    setcard_number,

    expiration_date,
    setexpiration_date,
  } = UseApi({
    setModleProfaile,
    setmodleOfBank,
  });

  useEffect(() => {
    viwoProfile();
    viweCreditCard();
  }, []);

  const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] =
    useState(true);

  const close = (bolan) => {
    setModleProfaile(bolan);
    setmodleOfBank(bolan);
  };

  return (
    <>
      <PopUp show={modleOfBank || modleProfaile} close={close}>
        {modleProfaile && (
          <ModelOfProfaile
            updateFormData={updateFormData}
            setUpdateFormData={setUpdateFormData}
            updateProfile={updateProfile}
            setModleProfaile={setModleProfaile}
          />
        )}

        {modleOfBank && (
          <ModelOfBank
            setMmodleOfBank={setmodleOfBank}
            addNewCreditCard={addNewCreditCard}
            card_holder_name={card_holder_name}
            setcard_holder_name={setcard_holder_name}
            card_number={card_number}
            setcard_number={setcard_number}
            expiration_date={expiration_date}
            setexpiration_date={setexpiration_date}
            creditCard={creditCard}
            setCreditCard={setCreditCard}
          />
        )}
      </PopUp>

      <main className="px-1 py-6 sm:px-6 lg:flex-auto lg:px-0 ">
        <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
          <div>
            <div className=" flex justify-between items-center ">
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  {`${t("setting_general.setting_general_profile.profile")}`}
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-500">
                  {`${t(
                    "setting_general.setting_general_profile.this_information"
                  )}`}
                </p>
              </div>
              <div>
                <button
                  type="button"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                  onClick={() => {
                    setModleProfaile(true);
                  }}
                >
                  {`${t("setting_general.setting_general_profile.update")}`}
                </button>
              </div>
            </div>

            <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
              <div className="pt-6 sm:flex ">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                  {`${t("setting_general.setting_general_profile.first_name")}`}
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">{data.first_name}</div>
                </dd>
              </div>
              <div className="pt-6 sm:flex ">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                  {`${t("setting_general.setting_general_profile.last_name")}`}
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">{data.last_name}</div>
                </dd>
              </div>
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                  {`${t(
                    "setting_general.setting_general_profile.email_address"
                  )}`}
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">{data.email}</div>
                </dd>
              </div>
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                  {`${t(
                    "setting_general.setting_general_profile.phone_number"
                  )}`}
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">{data.phone_number}</div>
                </dd>
              </div>
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                  {`${t("setting_general.setting_general_profile.country")}`}
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">
                    {data.country ? data.country.name_en : ""}
                  </div>
                </dd>
              </div>

              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                  {`${t("setting_general.setting_general_profile.city")}`}
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">
                    {data.city ? data.city.name_en : ""}
                  </div>
                </dd>
              </div>
            </dl>
          </div>

          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              {`${t("setting_general.setting_general_bank.bank_accounts")}`}
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              {`${t("setting_general.setting_general_bank.connect_bank")}`}
            </p>

            <ul className="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
              {creditCard.map((card, index) => (
                <li
                  key={index}
                  value={card.id}
                  className="flex justify-between gap-x-6 py-6"
                >
                  <div className="flex gap-x-4">
                    <RiVisaLine className="h-8 w-8 flex-none rounded-full text-indigo-500" />
                    <div className="min-w-0 flex-auto">
                      <p className=" text-lg font-semibold leading-6 text-gray-600/90">
                        {card.card_holder_name}
                      </p>
                      <p className="mt-1 truncate text-xs leading-5 text-gray-400">
                        {card.card_number}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-center items-center gap-5">
                    <div className="hidden sm:flex sm:flex-col sm:items-end">
                      <p className="text-sm leading-6 text-gray-400">
                        <span className="text-indigo-500 font-medium mr-1">
                          Expiration Date:
                        </span>
                        {card.expiration_date}
                      </p>

                      <p className="mt-1 text-xs leading-5 text-gray-400">
                        <span className="text-indigo-500 font-medium mr-1">
                          Created at:
                        </span>
                        {moment(card.created_at).format("DD/MM/YYYY")}
                      </p>
                    </div>
                    <button
                      onClick={() => {
                        deleteCreditCard(card.id);
                      }}
                    >
                      <BsFillTrashFill className=" h-4 w-4 text-red-900/75" />
                    </button>
                  </div>
                </li>
              ))}
            </ul>

            <div className="flex border-t border-gray-100 pt-6">
              <button
                onClick={() => {
                  setmodleOfBank(true);
                }}
                type="button"
                className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
              >
                <span aria-hidden="true">+</span>
                {`${t("setting_general.setting_general_bank.add_anthor_bank")}`}
              </button>
            </div>
          </div>

          {/* <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Language and dates
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-500">
              Choose what language and date format to use throughout your
              account.
            </p>

            <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                  Language
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">English</div>
                  <select
                    dir="rtl"
                    id="lang"
                    name="lang"
                    className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 outline-none focus:ring-2 focus:ring-inset sm:text-sm"
                  >
                    <option>ENG</option>
                    <option>AR</option>
                  </select>
                </dd>
              </div>
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                  Date format
                </dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <label>
                    Date
                    <input type="date" name="date" id="date" />
                  </label>
                </dd>
              </div>
              <Switch.Group as="div" className="flex pt-6">
                <Switch.Label
                  as="dt"
                  className="w-64 flex-none pr-6 font-medium text-gray-900"
                  passive
                >
                  Automatic timezone
                </Switch.Label>
                <dd className="flex flex-auto items-center justify-end">
                  <Switch
                    checked={automaticTimezoneEnabled}
                    onChange={setAutomaticTimezoneEnabled}
                    className={classNames(
                      automaticTimezoneEnabled
                        ? "bg-indigo-600"
                        : "bg-gray-200",
                      "flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        automaticTimezoneEnabled
                          ? "translate-x-3.5"
                          : "translate-x-0",
                        "h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                </dd>
              </Switch.Group>
            </dl>
          </div> */}
        </div>
      </main>
    </>
  );
}
